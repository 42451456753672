import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FurnitureItem} from "./App";

export function FurnitureCard({name, price, description}: FurnitureItem): React.ReactElement {
    const [images, setImages] = useState<string[] | null>(null);

    useEffect(() => {
        const loadImages = async () => {
            let tmpImage: string[] = [];

            for (let i = 1; i <= 4; i++) {
                try {
                    let image = await import(`./img/${name.toLowerCase()}/${i}.jpg`);
                    tmpImage.push(image);
                } catch (e) {
                    // Allow failing, since not all folders have the same number of images
                }
            }
            setImages(tmpImage);
        };
        loadImages();
    }, [name]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    if (images === null) {
        return <p>Loading...</p>;
    }

    return (
        <div className="p-6 rounded-lg shadow-md mb-8 ">
            <Slider {...settings}>
                {images.map((image: any, index) => (
                    <img
                        key={index}
                        src={image.default}
                        alt={`${name} Bild ${index + 1}`}
                        className="w-full rounded-lg shadow-md mb-4"
                    />
                ))}
            </Slider>
            <div className="text-center mt-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-2">{name}</h2>
                <p className="text-lg font-semibold text-gray-700 mb-2">Preis: {price}</p>
                <p className="text-gray-700">{description}</p>
            </div>
        </div>
    );
}
