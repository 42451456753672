import React from 'react';
import {FurnitureCard} from './FurnitureCard';

export interface FurnitureItem {
    name: string,
    description: string,
    price: string,
}

export function App() {
    const furnitureItems: FurnitureItem[] = [
        {
            name: "Sofa",
            description: "Sofa aus Kunstleder",
            price: "50 CHF"
        },
        {
            name: "Esstisch",
            description: "Ausziehbarer Esstisch aus Massivholz (es können 0-4 der Mittel-Platten eingesetzt werden)",
            price: "80 CHF"
        },
        {
            name: "Balkon-Sessel",
            description: "Sessel, geeignet für den Outdoor Gebrauch",
            price: "25 CHF"
        },
        {
            name: "Stuhl",
            description: "4 Freischwinger-Stühle",
            price: "15 CHF pro Stuhl"
        },
        {
            name: "Spiegelmöbel",
            description: "Drehbares Spiegelmöbel mit viel Stauraum",
            price: "50 CHF"
        },
        // {
        //     name: "Küchentisch",
        //     description: "Kleinerer Tisch",
        //     price: "15 CHF"
        // },
        // {
        //     name: "Nachttisch",
        //     description: "Nachttisch von Conforama mit 3 Schubladen",
        //     price: "15 CHF"
        // },

        // {
        //     name: "Schlafzimmer Lampe",
        //     description: "LED Leuchten (nicht austauschbar), Lampe in schwarz-gold",
        //     price: "15 CHF"
        // },
        // {
        //     name: "Spot Lampe",
        //     description: "Lampe mit 3 austauschbaren Glühbirnen",
        //     price: "5 CHF",
        // }
    ];

    return (
        <div className="container mx-auto py-8">
            <header className="text-center mb-8">
                <img src="/logo.jpg" alt="Logo" className="mx-auto mb-4" style={{maxWidth: "200px"}}/>
                <h1 className="text-3xl font-bold">Möbel zu verkaufen</h1>
                <p className="text-gray-700 p-4 rounded-lg shadow-md bg-amber-100 mt-4">
                    Alle Möbel sind an der <a className="text-blue-600 hover:underline" href="https://www.google.com/maps/place/Wingertlistrasse+42,+8405+Winterthur/@47.4885919,8.7572691,17z/data=!3m1!4b1!4m6!3m5!1s0x479a99e81c801b9d:0xb4b8ff8ad3157689!8m2!3d47.4885883!4d8.759844!16s%2Fg%2F11c5jm0cwf?entry=ttu">Wingertlistrasse 42, 8405 Winterthur</a> abzuholen, bevorzugt Mitte
                    Juni. Preise sind verhandelbar.
                </p>
            </header>

            <main className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {furnitureItems.map((item, index) => (
                    <FurnitureCard key={index} {...item} />
                ))}
            </main>
        </div>
    );
}
